// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_4689 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;

  .backstretch {
    position: relative;
  
    // for top of page gradient
    &:before {
      @include before(100%, 450px);
      background: url(/i/design/gradient.png) repeat-x top left;
      top: 0;
      left: 0;
    }
  
    &:after {
      @include before(100%, 450px);
      background: url(/i/design/gradient.png) repeat-x bottom left;
      bottom: -36px;
      left: 0;
      transform: scale(-1);

      @media only screen and (max-width: 1400px) {
        bottom: -14px;
      }

      @media only screen and (max-width: 1120px) {
        bottom: -100px;
      }
    }

    img {

      @-webkit-keyframes zoom {
        from {
          -webkit-transform: scale(1);
          transform: scale(1)
        }
    
        to {
          -webkit-transform: scale(1.1);
          transform: scale(1.1)
        }
      }
    
      @-moz-keyframes zoom {
        from {
          -moz-transform: scale(1);
          transform: scale(1)
        }
    
        to {
          -moz-transform: scale(1.1);
          transform: scale(1.1)
        }
      }
    
      @keyframes zoom {
        from {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          transform: scale(1)
        }
    
        to {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          transform: scale(1.1)
        }
      }
  
      -webkit-animation:zoom 30s ease forwards infinite;
  -moz-animation:zoom 30s ease forwards infinite;
  animation:zoom 30s ease forwards infinite
    }
}
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  // &:before {
  //   @include before(100%, 450px);
  //   background: url(/i/design/gradient.png) repeat-x top left;
  //   top: 0;
  //   left: 0;
  // }

  &:after {
    @include before(100%, 450px);
    background: url(/i/design/gradient.png) repeat-x bottom left;
    bottom: -96px;
    left: 0;
    transform: scale(-1);
  }

  img {
    top: 0px !important;


  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 250px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 56px;
  text-shadow: 5px 5px 2px rgba(0,0,0,0.4);

  @media only screen and (max-width: 1120px) {
    bottom: 75px;
  }

  @media only screen and (max-width: 990px) {
    padding: 20px;
    font-size: 50px;
  }

  @media only screen and (max-width: 730px) {
    font-size: 45px;
    bottom: 30px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 42px;
  }

  @media only screen and (max-width: 460px) {
    font-size: 30px;
  }

  &:before {
    position: absolute;
    content: "ACHIEVING EXELLENCE TOGETHER";
    font-size: 100px;
    white-space: nowrap;
    letter-spacing: 1px;
    opacity: 0.2;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;

    @keyframes strapline-in {
      0% {
        opacity: 0;
      }
    
      100% {
        opacity: 0.2;
      }
    }

    animation: strapline-in 1.5s ease-in 0s 1 normal forwards;

    @media only screen and (max-width: 1590px) {
      opacity: 0.1;
    }

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }
}